<template>
  <b-navbar
    sticky
    justified
    fill
    style="height: 5rem; width: 100%"
    class="bg-dark text-din-black text-regular"
  >
    <b-navbar-brand align="start">
      <!-- <font-awesome-icon v-if="companyImage === 'default' " icon="building" size="6x" class="mt-10" :style="{ color: 'white' }" /> -->
      <div class="">
        <a
          class="cursor-pointer"
          target="_blank"
          href="https://designsingapore.org/"
        >
          <img
            :src="latestCompanyImage"
            style="height: 3rem; margin-top: 1.5rem; margin-bottom: 1.5rem"
            class="w-auto object-scale-down"
          >
        </a>
      </div>
    </b-navbar-brand>
    <b-navbar-nav
      class="ml-auto"
      align="end"
    >

      <div
        class="d-md-flex d-none flex-row text-din-bold align-items-center"
      >
        <router-link
          class="text-light mr-2"
          style="width: 50px;"
          :to="{name: $route.name === 'listings-event' ? 'profile' : 'listings-event' }"
        >
          Home
        </router-link>

        <router-link
          class="text-light mr-2"
          style="width: 100px;"
          :to="{name: 'listings-page' }"
        >
          Design Jobs
        </router-link>

        <a
          v-if="$route.name === 'listings-event'"
          class="text-light mr-2"
          style="width: 90px;"
          @click="$scrollTo('highlights')"
        >
          Highlights
        </a>

        <a
          class="text-light mr-2"
          style="width: 90px;"
          href="mailto:talent@designsingapore.org"
        >
          Contact Us
        </a>

        <!-- <router-link
          class="text-dark"
          :to="{name: 'auth-login'}"
        >
          Log In
        </router-link> -->

        <div
          v-if="isLoggedIn"
          class="mr-2"
          style="min-width: 100px;"
        >
          <router-link
            class="text-white"
            :to="{
              name: $store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT
                ? 'manage-applications'
                : 'home'
            }"
          >
            <b-button
              class="w-full shadow-lg text-din-black text-regular"
              variant="primary"
              style="width: auto;"
            >
              {{
                $store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT
                  ? 'Dashboard'
                  : 'Dashboard'
              }}
            </b-button>
          </router-link>

        </div>

        <div
          v-else
          class="mr-2"
        >
          <b-button
            class="w-full shadow-lg text-din-black text-regular"
            variant="primary"
            @click="activateLogin"
          >Sign In</b-button>
        </div>

        <!-- <router-link class="text-white" :to="{name: 'profile'}">Home</router-link>
          <router-link class="text-white" :to="{name: 'profile'}">Home</router-link> -->
      </div>

      <div
        v-if="true"
        class="d-md-none d-block text-din-bold"
      >
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          class="mobile-nav-dropdown text-right"
          no-caret
          right
        >
          <template #button-content>
            <div>
              <feather-icon
                icon="MenuIcon"
                size="32"
              />
            </div>
          </template>
          <b-dropdown-item href="#">
            <router-link
              class="text-dark"
              :to="{name: $route.name === 'listings-event' ? 'profile' : 'listings-event' }"
            >
              Home
            </router-link>
          </b-dropdown-item>

          <b-dropdown-item href="#">
            <router-link
              class="text-dark"
              :to="{name: 'listings-page' }"
            >
              Design Jobs
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item target="_blank" href="https://designsingapore.org/contact-us/">
            <a
              class="text-dark"
              href="mailto:talent@designsingapore.org"
            >
              Contact Us
            </a>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="!isLoggedIn"
            href="#"
          >
            <router-link
              class="text-dark"
              :to="{name: 'auth-login'}"
            >
              Sign In
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item
            v-else
            href="#"
          >
            <router-link
              class="text-dark"
              :to="{
                name: $store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT
                  ? 'manage-applications'
                  : 'home'
              }"
            >
              {{
                $store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT
                  ? 'Dashboard'
                  : 'Dashboard'
              }}
            </router-link>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-navbar-nav>

    <router-view @login-state="updateUserLoggedIn" />
    <b-modal
      v-model="login_form"
      centered
      size="lg"
      hide-footer
      title=""
    >
      <b-row
        cols="12"
        class="m-0 p-0 text-din-regular"
      >
        <b-tabs
          v-model="active_tab"
          align="center"
          class="w-100 ml-1"
          fill
        >
          <b-tab
            title="Sign In"
            class="w-100"
          >
            <LoginForm
              class="w-100"
              which-page="listings"
              :event-id="this.$router.currentRoute.path"
              @user-logged-in="afterUserLoggedIn"
            />
          </b-tab>
          <b-tab
            title="Registration"
            class="w-100"
          >
            <RegistrationForm
              class="w-100"
              which-page="listings"
              account-type="Applicant"
              @user-registered="afterUserLoggedIn"
            />
          </b-tab>
        </b-tabs>
      </b-row>
    </b-modal>
  </b-navbar>
</template>

<script>
import {
  BButton,
  BNavbar,
  BModal,
  BNavbarNav,
  BNavbarBrand,
  BTabs,
  BTab,
  BRow,
} from 'bootstrap-vue'

import { isUserLoggedIn } from '@/auth/utils'

import LoginForm from '@/views/authentication/login/LoginForm.vue'
import RegistrationForm from '@/views/authentication/registration/RegistrationForm.vue'
// import designCouncilImage from '@/assets/images/pages/listings/DESIGNSINGAPORE COUNCIL PTE. LTD..png'
// import designCouncilImage from '@/assets/images/pages/listings/DESIGNSINGAPORE COUNCIL PTE. LTD - cropped..png'
// import designCouncilImage from '@/assets/images/icons/designsingapore-logo-color-transparent.png'
// import designCouncilImage from '@/assets/images/icons/designsingapore-logo-color-transparent.png'
import designCouncilImage from '@/assets/images/logo/designsingapore-logo-white.png'

export default {
  components: {
    LoginForm,
    RegistrationForm,

    BNavbarNav,
    BButton,
    BModal,
    BNavbar,
    BNavbarBrand,
    BTabs,
    BTab,
    BRow,
  },
  props: {
    companyImage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoggedIn: false,
      login_form: false,
      active_tab: 0,
      footer_class: 'w-full',
      latestCompanyImage: 'not-set',
      special_styling: '',
      accountancy_event: false,
      isUserLoggedIn,

      // designCouncilImage: require('@assets/images/pages/listings/DESIGNSINGAPORE COUNCIL PTE. LTD..png')
    }
  },
  watch: {
    companyImage(newLink) {
      this.latestCompanyImage = newLink
      this.latestCompanyImage = designCouncilImage
    },
    '$store.state.auth.ActiveUser'(val){
      this.isLoggedIn = isUserLoggedIn()
    },
  },
  created() {
    this.isLoggedIn = isUserLoggedIn()

    this.latestCompanyImage = designCouncilImage
    if (
      this.$router.currentRoute.path === '/listings'
    ) {
      this.latestCompanyImage = `${this.$bucketUrl}/media/hq_logo.png`
    }
    let { event_id } = this.$route.params
    if (
      this.$route.params.company
      && this.$route.params.company.endsWith('_g')
    ) {
      event_id = this.$route.params.company.replace('_g', '')
      if (event_id === '61dcd0dc62adac05c99714c4') {
        this.accountancy_event = true
      }
      if (event_id === 'cc04238b-e18f-4d74-adaf-598d1a99b6ba') {
        // this.latestCompanyImage = this.designCouncilImage
        this.latestCompanyImage = designCouncilImage
      }
      this.latestCompanyImage = designCouncilImage
    } else if (event_id === '61dcd0dc62adac05c99714c4') {
      this.accountancy_event = true
    }

  },
  methods: {

    afterUserLoggedIn() {
      this.isLoggedIn = true
      this.login_form = false

      this.$router.go()
    },
    updateCompanyImage(image) {
      if (image === 'default') {
        this.latestCompanyImage = `${this.$bucketUrl}/media/hq_logo.png`
      } else {
        this.latestCompanyImage = image
      }
    },
    activateLogin() {
      if (isUserLoggedIn()) {
        this.isLoggedIn = true

        this.login_form = false
      } else {
        this.login_form = true
      }
    },
    updateUserLoggedIn() {
      this.isLoggedIn = isUserLoggedIn()
    },
  },
}
</script>
<style lang="scss" scoped>

#app > div > header {
  background-color: #10163a;
}

.bg-transparent {
  background: none !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
}
// .navbar-light {
// 	background: none !important;
// 	background-color: rgba(255, 255, 255, 0.5) !important;
// }
</style>
