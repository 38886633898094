<template>
  <div class="">
    <b-modal
      v-model="popup_quiz"
      centered
      hide-footer
      no-close-on-backdrop
      title=""
      @close="closePopup"
    >
      <div
        v-if="checkOtherQuestions.length > 0"
        class="mt-1"
      >
        <h5 class="">
          Please answer the following question(s)
        </h5>
      </div>
      <hr v-if="checkOtherQuestions.length > 0">
      <div
        v-for="(query, idx) in checkOtherQuestions"
        :key="idx+'-other'"
        class="mt-1"
      >
        <b-form-group
          v-if="!query.question.toLowerCase().includes('nric')"
          class="w-100"
          :label="query.question"
          :label-for="query.question"
        >
          <b-form-input
            :id="query.question"
            v-model="query.answer"
          />
        </b-form-group>
      </div>
      <div
        v-for="(query, index) in popup_questions"
        :key="index"
        class="mt-1"
      >
        <div
          v-if="query.question.toLowerCase().includes('nric')"
          class="w-100 mt-2"
        >
          <hr>
          <h6 class="mt-1">Other information required by the Organiser/Employer</h6>
          <hr>
          <div
            cols="12"
            class="mt-2 w-100"
          >
            <b-form-group
              class="w-100"
              :label="query.question"
              :label-for="query.question"
            >
              <b-form-input
                :id="query.question"
                v-model="query.answer"
                :state="validateNRIC(query.answer)"
              />
              <b-form-invalid-feedback>
                A valid NRIC number is required.
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <b-form-checkbox
            v-model="save_nric"
            :disabled="!validateNRIC(query.answer)"
            class="mt-1"
          >Save NRIC number for future applications.</b-form-checkbox>

          <hr>

          <div class="d-flex justify-content-between my-2">
            <b-form-radio
              v-model="resume_choice"
              name="existing-resume"
              value="existing-resume"
              class="mr-1"
            >
              Use Existing Resume
            </b-form-radio>

            <b-form-radio
              v-model="resume_choice"
              name="new-resume"
              value="new-resume"
              class="ml-1"
            >
              Upload A New Resume
            </b-form-radio>
          </div>
          <div
            v-if="resume_choice == 'new-resume'"
            class="mx-auto mt-1 d-flex flex-row"
          >
            <file-pond
              ref="pond-resume"
              v-model="resumeFile"
              name="resume-upload"
              label-idle="Upload a new resume file."
              accepted-file-types="application/pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              style="height: 60px!important;width: 50%;"
              :server="resumeServer"
              :files="resumeFiles"
              credits="false"
              @init="handleResumePondInit"
            />
            <b-button
              v-if="resume_file && resume_choice === 'new-resume'"
              id="#button-with-loading"
              class="shadow-md"
              style="height: 60px!important;width: 50%;"
              variant="outline-primary"
              @click="viewUploadedResume"
            >View your resume</b-button>
          </div>
        </div>
      </div>
      <div class="d-flex my-2 w-100">
        <b-button
          block
          :disabled="!checkIfAnswered()"
          class="mx-auto"
          variant="primary"
          @click="joinEvent(popup_event, $event, true)"
        >Apply Now</b-button>
      </div>
    </b-modal>

    <b-modal
      v-model="view_resume_popup"
      centered
      hide-footer
      size="lg"
      title="Resume File"
      @close="view_resume_popup = false"
    >
      <div
        class="mx-2 w-full"
        style="height: 100vh!important;"
      >
        <!-- Math.random() is to prevent image being cache and display the previous file, when user upload or changed the resume file -->
        <iframe
          v-if="document_type == 'pdf'"
          :src="`${file_source}?${Math.random(0, 30000000)}`"
          width="100%"
          style="height: 100vh!important;"
          frameborder="0"
        />

        <iframe
          v-else
          :src="`${file_source}?${Math.random(0, 30000000)}`"
          width="100%"
          style="height: 100vh!important;"
          frameborder="0"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { listingsMixin } from '@/views/event-management-system/mixins/listingsMixins'

import { v4 as uuidv4 } from 'uuid'
import { singleUpload, deleteObjectByKey } from '@/components/awsS3'
import vueFilePond from 'vue-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
)

export default {
  components: {
    FilePond,
  },
  mixins: [listingsMixin],
  props: {
    activatePopup: { type: Boolean, default: false },
    saveNric: { type: Boolean, default: false },
    jobQuestions: { type: Array, default: null },
    allApplications: null,
  },
  data() {
    return {
      applicationId: null,

      // file-pond
      resumeFiles: [],
      resumeServer: {},
      resumeFile: null, // keep track if the file-pond has a file

      // view resume
      view_resume_popup: false,
      document_type: 'pdf',
      file_source: '',
    }
  },
  computed: {
    checkOtherQuestions() {
      return this.popup_questions.filter(
        item => !item.question.toLowerCase().includes('nric'),
      )
    },
  },
  watch: {
    resumeFile(val) {
      // keep track if the file-pond has a file
      if (this.$isEmpty(val)) {
        this.resume_file = null
        this.checkIfAnswered()
      }
    },
    resume_choice(val, oldVal) {
      if (oldVal === 'new-resume') {
        if (!this.$isEmpty(this.resume_file)) {
          deleteObjectByKey(this.resume_file, this)
        }
        this.resume_file = null
        this.checkIfAnswered()
      }
    },
    allApplications: {
      handler(val) {
        this.registered = this.allApplications
      },
    },
    activatePopup: {
      handler(val, oldVal) {
        if (val) {
          this.popup_quiz = true
          const event = JSON.parse(localStorage.getItem('applied_job'))
          this.popup_event = event
        }
      },
    },
    jobQuestions: {
      handler(val, oldVal) {
        this.popup_questions = val
      },
    },
    saveNric: {
      handler(val, oldVal) {
        this.save_nric = val
      },
    },
    deep: true,
  },
  mounted() {
    this.registered = this.allApplications
  },
  methods: {
    closePopup(evt) {
      this.popup_quiz = false
      this.$emit('application-form', false)
    },

    handleResumePondInit() {
      this.applicationId = uuidv4()
      this.$emit('application-id', this.applicationId) // set job_application_id to server

      const outerThis = this
      this.$refs['pond-resume'][0]._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()

            singleUpload(file, 'user-resume', `${outerThis.applicationId}.${extension}`, load, error, progress, abort, null, outerThis)
          },
        },
      })
    },

    viewUploadedResume() {
      const fileUrl = `${this.$bucketUrl}/${this.resume_file}`
      if (this.resume_file && this.resume_file.endsWith('pdf')) {
        this.document_type = 'pdf'
        this.file_source = `${fileUrl}?random=${new Date().getTime()}`
      } else {
        this.document_type = 'docs'
        this.file_source = `https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`
      }
      this.view_resume_popup = true
    },
  },
}
</script>
